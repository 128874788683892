import React from 'react'

import { Link } from 'gatsby'
import { Category } from '../../types/types'
import { CarPageQueryQuery } from '../../types/graphql-types'

type Props = {
  id: string
  name: string
  description: string
  priceInEUR: string
  oldPriceInEUR?: string
  categories: Category[]
  gallery: CarPageQueryQuery['pimcore']['getCar']['gallery']
}

const ProductFull = ({
  id,
  name,
  description,
  priceInEUR,
  oldPriceInEUR,
  categories,
  gallery,
}: Props): React.ReactElement | null => {
  if (!gallery) {
    return null
  }

  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-md-push-2">
            <div id="product-main-img">
              <div className="product-preview">
                <img
                  src={`https://demo.pimcore.fun/${gallery[0].image.fullpath}`}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="col-md-2  col-md-pull-5">
            <div id="product-imgs">
              {gallery.map(({ image }) => (
                <div className="product-preview" key={image.id}>
                  <img
                    src={`https://demo.pimcore.fun/${image.fullpath}`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-5">
            <div className="product-details">
              <h2 className="product-name">{name}</h2>

              <div>
                <h3 className="product-price">
                  {priceInEUR}{' '}
                  {oldPriceInEUR && (
                    <del className="product-old-price">{oldPriceInEUR}</del>
                  )}
                </h3>
                <span className="product-available">In Stock</span>
              </div>
              <p dangerouslySetInnerHTML={{ __html: description }}></p>

              <div className="product-options">
                <label>
                  Size
                  <select className="input-select">
                    <option value="0">X</option>
                  </select>
                </label>
                <label>
                  Color
                  <select className="input-select">
                    <option value="0">Red</option>
                  </select>
                </label>
              </div>

              <div className="add-to-cart">
                <button
                  className="snipcart-add-item add-to-cart-btn"
                  data-item-id={id}
                  data-item-image={`https://demo.pimcore.fun${gallery[0].image.fullpath}`}
                  data-item-price={priceInEUR}
                  data-item-description={
                    description?.replace(/(<([^>]+)>)/gi, '').substr(0, 100) +
                    '...'
                  }
                  data-item-url={`p/cars/${id}`}
                  data-item-name={name}
                >
                  <i className="fa fa-shopping-cart"></i> add to cart
                </button>
              </div>

              <ul className="product-links">
                <li>Category:</li>
                {categories.map(({ id, name }) => (
                  <li key={id}>
                    <Link
                      to={`/category/${name.replace(' ', '-').toLowerCase()}`}
                    >
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="product-links">
                <li>Share:</li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12">
            <div id="product-tab">
              <ul className="tab-nav">
                <li className="active">
                  <a data-toggle="tab" href="#tab1">
                    Description
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#tab2">
                    Details
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane fade in active">
                  <div className="row">
                    <div className="col-md-12">
                      <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>
                  </div>
                </div>

                <div id="tab2" className="tab-pane fade in">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductFull
