import React from 'react'
import { graphql, PageProps } from 'gatsby'
import ProductFull from '../components/ProductFull'
import { Category } from '../../types/types'
import { CarPageQueryQuery } from '../../types/graphql-types'

const Car = ({ data }: PageProps<CarPageQueryQuery>): React.ReactElement => {
  const {
    id,
    name,
    description,
    priceInEUR,
    categories,
    gallery,
  } = data.pimcore.getCar

  return (
    <div>
      <ProductFull
        id={id}
        name={name}
        description={description}
        priceInEUR={priceInEUR}
        categories={categories}
        gallery={gallery}
      />
    </div>
  )
}

type PageQuery = {
  pimcore: {
    getCar: {
      name: string
      description: string
      priceInEUR: string
      categories: Array<Category>
    }
  }
}

export const pageQuery = graphql`
  query CarPageQuery($id: Int!) {
    pimcore {
      getCar(id: $id) {
        id
        name
        description
        priceInEUR
        categories {
          ... on PIMCORE_object_Category {
            id
            name
          }
        }
        gallery {
          image {
            id
            fullpath
          }
        }
      }
    }
  }
`

export default Car
